// import React from 'react';
// import { useTimer } from 'react-timer-hook';

// function MyTimer({ expiryTimestamp }) {

//   const {

//     seconds,
//     minutes,
//     hours,
    
//   } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


//   return (
//     <div>
//       <div style={{marginTop:6, marginLeft:15}} >
//         <span style={{fontSize:'0.8rem', color:'red'}}>Expired In:- </span><span style={{fontSize:'0.8rem', backgroundColor:'white', borderRadius:'0.1rem', padding:5}} >{hours} hrs</span> <span style={{fontSize:'0.8rem', backgroundColor:'white', borderRadius:'0.1rem', padding:5}} >{minutes} min</span> <span style={{fontSize:'0.8rem', backgroundColor:'white', borderRadius:'0.1rem', padding:5}}>{seconds} sec</span>
//       </div>
//       {/* <button onClick={start}>Start</button>
//       <button onClick={pause}>Pause</button>
//       <button onClick={resume}>Resume</button> */}
//       {/* <button onClick={() => {
//         const time = new Date();
//         time.setSeconds(time.getSeconds() + 300);
//         restart(time)
//       }}>Restart</button> */}
//     </div>
//   );
// }

// export default MyTimer



import React from 'react';
import { useTimer } from 'react-timer-hook';

// Function to convert total seconds into hours, minutes, and seconds
function convertSeconds(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    return { hours, minutes, seconds };
}

function MyTimer({ currentMinutss }) {
    // Convert currentMinuts to total seconds
    const { hours, minutes, seconds } = convertSeconds(currentMinutss);
    
    // Create a new date object for the expiry timestamp
    const expiryTimestamp = new Date();
    expiryTimestamp.setHours(expiryTimestamp.getHours() + hours);
    expiryTimestamp.setMinutes(expiryTimestamp.getMinutes() + minutes);
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + seconds);
    
    const { seconds: timerSeconds, minutes: timerMinutes, hours: timerHours } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn('onExpire called')
    });

    return (
        <div>
            <div style={{ marginTop: 6, marginLeft: 15 }}>
                <span style={{ fontSize: '0.8rem', color: 'red' }}>Expired In: </span>
                <span style={{ fontSize: '0.8rem', backgroundColor: 'white', borderRadius: '0.1rem', padding: 5 }}>{timerHours} hrs</span>
                <span style={{ fontSize: '0.8rem', backgroundColor: 'white', borderRadius: '0.1rem', padding: 5 }}>{timerMinutes} min</span>
                <span style={{ fontSize: '0.8rem', backgroundColor: 'white', borderRadius: '0.1rem', padding: 5 }}>{timerSeconds} sec</span>
            </div>
        </div>
    );
}

export default MyTimer;
