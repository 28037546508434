import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaBusAlt, FaFile, FaHandPeace, FaPencilAlt, FaTruck, FaUserPlus, FaUsers } from 'react-icons/fa';
import ListSubheader from '@mui/material/ListSubheader';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useAppStore } from '../AppStore/AppStore';
import { Collapse, Typography } from '@mui/material';
import { useEffect } from 'react'
import Paper from '@mui/material/Paper';
import TopCard from '../TopCard';
import Grid from '@mui/material/Grid';
import Chart from '../Chart/Chart';
import Customer from '../Customers/Customer';
import CorporateCustomer from '../CorporateCustomer/CorporateCustomer';
import LorryWala from '../LorryWala/LorryWala';
import LorryBroker from '../LorryBroker/LorryBroker';
import MainCustomer from '../MainCustomer/MainCustomer';
import MainLorryBroker from '../MainLorryBroker/MainLorryBroker';
import MainCorporateCustomer from '../MainCorporateCustomer/MainCorporateCustomer';
import MainLorryWala from '../MainLorryWala/MainLorryWala';
import axios from 'axios';
import logo from '../../../GlobleImageContainer/images/PrimeMoovers.png'
import Reports from '../Reports/Reports';
import Transactions from '../../Transactions/Transactions';

const drawerWidth = 260;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'Auto',
    color: theme.palette.text.secondary,
}));

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function Sidebar() {


    const [open9, setOpen9] = React.useState(true);

    const handleClick = () => {
        setOpen9(!open9);
    };

    const [open3, setOpen3] = React.useState(false);


    useEffect(() => {
        setOpen3(true)
        setOpen3(true)
    }, [])

    const [lorryWala, setLorryWala] = useState([])

    const lorryWalas = () => {
        const userType = 'LorryWala'
        axios.post('https://pmapi.emedha.in/users/lorry_wala', {
            userType
        }).then((res) => {
            setLorryWala(res.data.data)
        })
    }

    const [customers, setCustomer] = useState([])

    const customer = () => {
        const userType = 'Customer'
        axios.post('https://pmapi.emedha.in/users/customer', {
            userType
        }).then((res) => {
            setCustomer(res.data.data)
        })
    }

    const [brokers, setBroker] = useState([])

    const lorryBroker = () => {
        const userType = 'LorryBroker'
        axios.post('https://pmapi.emedha.in/users/lorry_broker', {
            userType
        }).then((res) => {
            setBroker(res.data.data)
        })
    }

    useEffect(() => {
        lorryWalas()
        customer()
        lorryBroker()
    }, [])

    const theme = useTheme();
    const [dopen, setOpen] = React.useState(true);

    const open = useAppStore((state) => state.dopen)

    const handleDrawerClose = () => {
        setOpen(!open);
    };


    const [isCustomer, setIsCustomer] = useState(false)
    const [isHome, setIsHome] = useState(true)
    const [isTransaction,setIsTransactions]=useState(false)
    const [isIsLorryWala, setIsLorryWala] = useState(false)
    const [isLorryBroker, setIsLorryBroker] = useState(false)
    const [isLorryCorporate, setIsLorryCorporate] = useState(false)
    const [userPage, setUserPage] = useState(false)
    const [isReport, setIsReport] = useState()

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer style={{ background: 'red', backgroundColor: 'red' }} elevation={1} variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>

                <List style={{ marginTop: 45 }}>
                    <img style={{ position: 'relative', top: -18, left: 10 }} src={logo} width='170px' />
                    <ListItem onClick={() => {
                        setIsHome(true)
                        setIsLorryBroker(false)
                        setIsLorryWala(false)
                        setIsCustomer(false)
                        setIsLorryCorporate(false)
                        setUserPage(false)
                        setIsReport(false)
                        setIsTransactions(false)
                    }} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaPencilAlt fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }} onClick={() => {
                         setIsHome(false)
                         setIsLorryBroker(false)
                         setIsLorryWala(false)
                         setIsCustomer(false)
                         setIsLorryCorporate(false)
                         setUserPage(true)
                         setIsTransactions(true)
                    }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaHandPeace fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Transactions" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                <hr />
                            </ListSubheader>
                        }
                    >
                        <ListItemButton onClick={handleClick}>
                            <ListItemIcon>
                                <FaUsers size={25} />
                            </ListItemIcon>
                            <ListItemText style={{ position: 'relative', right: '0.4rem' }} primary="User Managements" />
                            {open9 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open9} timeout="auto" unmountOnExit>
                            <List onClick={() => {
                                setIsHome(false)
                                setIsLorryBroker(false)
                                setIsLorryWala(false)
                                setIsCustomer(true)
                                setIsLorryCorporate(false)
                                setUserPage(true)
                                setIsReport(false)
                                setIsTransactions(false)
                            }} component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <FaUserPlus />
                                    </ListItemIcon>
                                    <ListItemText primary="Retail Clients" />
                                </ListItemButton>
                            </List>

                            <List onClick={() => {
                                setIsHome(false)
                                setIsLorryBroker(false)
                                setIsLorryWala(false)
                                setIsCustomer(false)
                                setIsLorryCorporate(true)
                                setUserPage(true)
                                setIsReport(false)
                                setIsTransactions(false)
                            }} component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <FaUserPlus />
                                    </ListItemIcon>
                                    <ListItemText primary="Corporate Clients" />
                                </ListItemButton>
                            </List>
                            <List onClick={() => {
                                setIsHome(false)
                                setIsLorryBroker(false)
                                setIsLorryWala(true)
                                setIsCustomer(false)
                                setIsLorryCorporate(false)
                                setUserPage(true)
                                setIsReport(false)
                                setIsTransactions(false)
                            }} component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <FaTruck />
                                    </ListItemIcon>
                                    <ListItemText primary="Cargo Owners" />
                                </ListItemButton>
                            </List>
                            <List onClick={() => {
                                setIsHome(false)
                                setIsLorryBroker(true)
                                setIsLorryWala(false)
                                setIsCustomer(false)
                                setIsLorryCorporate(false)
                                setUserPage(true)
                                setIsReport(false)
                                setIsTransactions(false)
                            }} component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <FaBusAlt />
                                    </ListItemIcon>
                                    <ListItemText primary="Cargo Brokers" />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </List>
                    <ListItem
                        onClick={() => {
                            setIsHome(false)
                            setIsLorryBroker(false)
                            setIsLorryWala(false)
                            setIsCustomer(false)
                            setIsLorryCorporate(false)
                            setUserPage(true)
                            setIsReport(true)
                            setIsTransactions(false)
                        }}
                        disablePadding sx={{ display: 'block' }} >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <FaFile fontSize={20} />
                            </ListItemIcon>
                            <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
            </Drawer>
            <Box className='no-args w-100 p-3 pt-4' sx={{ mt: 1 }}>
                {
                    (isHome && !userPage) && (
                        <Box sx={{ flexGrow: 1, mt: 6.6 }}>
                            <Grid container spacing={2}>
                                <Grid style={{ height: '9.8rem', marginBottom: '0.6rem' }} item xs={12}>
                                    <TopCard lb={brokers} lw={lorryWala} cstmr={customers} />
                                </Grid>
                                <Grid style={{ position: 'relative' }} item xs={7}>
                                    <Item sx={{ height: 75 + 'vh', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', position: 'absolute', width: '96%' }} elevation={1}>
                                        
                                        <Chart />
                                    </Item>
                                </Grid>
                                <Grid item xs={5}>
                                    <Item sx={{ height: 75 + 'vh', p: 2 }} cstmr={customers} elevation={1}>
                                        <Customer cstmr={customers} />
                                    </Item>
                                </Grid>
                                <Grid item xs={12}>
                                    <Item sx={{ height: 70 + 'vh', p: 2 }} elevation={1}>
                                        <CorporateCustomer />
                                    </Item>
                                </Grid>

                                <Grid item xs={6}>
                                    <Item sx={{ height: 70 + 'vh', p: 2 }} elevation={1}>
                                        <LorryWala lw={lorryWala} />
                                    </Item>
                                </Grid>
                                <Grid item xs={6}>
                                    <Item sx={{ height: 70 + 'vh', p: 2 }} elevation={1}>
                                        <LorryBroker lb={brokers} />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                }
                {
                    userPage && (
                        <Grid item xs={12}>
                            <Item sx={{ height: 84.4 + 'vh', p: 2, mt: 7 }} elevation={0}>
                                {
                                    isCustomer && (
                                        <MainCustomer data={customers} />
                                    )
                                }
                                {
                                    isLorryCorporate && (
                                        <MainCorporateCustomer />
                                    )
                                }
                                {
                                    isLorryBroker && (
                                        <MainLorryBroker data={brokers} />
                                    )
                                }
                                {
                                    isIsLorryWala && (
                                        <MainLorryWala data={lorryWala} />
                                    )
                                }
                                {
                                    isReport && (
                                        <div>
                                            
                                             <Reports></Reports>
                                        </div>
                                    )
                                }
                                {
                                    isTransaction && (
                                        <div>
                                            
                                            <Transactions  />
                                        </div>
                                    )
                                }
                            </Item>
                        </Grid>
                    )
                }
                
            </Box>
           
        </Box >
    );
}
