import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import SimpleBackdrop from "../BackDrop/BackDrop";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import {
  useJsApiLoader,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Skeleton, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlaceIcon from "@mui/icons-material/Place";
import {
  FaCaretDown,
  FaCaretLeft,
  FaCircle,
  FaMapMarkerAlt,
  FaTruck,
} from "react-icons/fa";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import loading from "../GlobleImageContainer/images/loading.gif";
import moment from "moment";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import TimePicker from "react-time-picker";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "Auto",
  color: theme.palette.text.secondary,
}));

const containerStyle = {
  position: "absolute",
  right: "0.3rem",
  top: "12rem",
  left: "0.3rem",
  bottom: "0.9rem",
};

const center = {
  lat: 12.972442,
  lng: 77.580643,
};

const CreateBids = ({ firstName, lastName }) => {
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open6 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open7 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [open5, setOpen5] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    setOpen5(true);
  };

  const handleClose = () => {
    setOpen5(false);
  };

  const bidSuccessFullySend = () => {
    Swal.fire({
      title: "Congratulation",
      text: "Your bid Successfully sent, You want to send another bid !...",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        setOpen1(true);
        setOpen(false);
        setOpen2(false);
        setMaterial("");
        setEndLocation("");
        setSatrtLocation("");
        setVehicle("Select Vehicle Type");
        setVehicleCapacity("Select Vehicle Capacity");
        setIslocation(true);
        setIsCapacity(false);
        setDiraction("0");
        setInputList([
          {
            vehicleType: "",
            vehicleCapacity: "",
            material: "",
            vehicleNumber: "",
            dispatchTime: "",
          },
        ]);
      } else {
        window.location.reload();
      }
    });
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDIhf9a0TCregc8lSd8KdK4RDfRN8wyhbk",
    libraries: ["places"],
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [startLocation, setSatrtLocation] = useState("");
  const [endLocation, setEndLocation] = useState("");
  const [vehicle, setVehicle] = useState("Select Vehicle Type");
  const [vehicleCapacity, setVehicleCapacity] = useState(
    "Select Vehicle Capacity"
  );
  const [isCapacity, setIsCapacity] = useState(false);
  const [capacity, setCapacity] = useState([]);
  const [material, setMaterial] = useState("Perisable");
  const [isBackDrop, setIsBackDrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState(1);
  const [bidsWait3, setBidsWait3] = useState(false);

  const [diractionResponce, setDirectionResponce] = useState();
  const [diraction, setDiraction] = useState("0");
  const [duration, setDuration] = useState("");
  const originRef = useRef();
  const destiationRef = useRef();

  const google = window.google;

  const calculateRoute = async () => {
    if (originRef.current.value === "" || destiationRef.current.value === "") {
      return;
    }

    const diractionService = new google.maps.DirectionsService();

    console.log(diractionService);

    const result = await diractionService.route({
      origin: originRef.current.value,
      destination: destiationRef.current.value,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    console.log(result);

    setDirectionResponce(result);
    setDuration(result.routes[0].legs[0].duration.text);
    setDiraction(result.routes[0].legs[0].distance.text);
  };

  const bidsConfirmationAlert = (startLocation, endLocation, index) => {
    setOpen5(false);
    Swal.fire({
      title: "Re Confirm",
      text: "Please re-confirm your BID details.",
      showCancelButton: true,
      confirmButtonColor: "rgb(225, 174, 78)",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        sendBusiness(startLocation, endLocation, index);
      } else {
        return;
      }
    });
  };

  const sendBusiness = (startLocation, endLocation, index) => {
    if (
      inPutlist[index].vehicleType === "" ||
      inPutlist[index].vehicleCapacity === "" ||
      inPutlist[index].despatchTime === "" ||
      inPutlist[index].dispatchTime === ""
    ) {
      setOpen5(false);
      Swal.fire({
        title: "Warning",
        text: "Any input fileds can't be empty",
        showCancelButton: false,
        confirmButtonColor: "red",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          return;
        }
      });
    } else {
      setBidsWait3(true);
      const customerName = `${firstName} ${lastName}`;

    //   https://pmapi.emedha.in/send/bids
      
      axios
        .post("https://pmapi.emedha.in/send/bids", {
          startLocation,
          client_id: sessionStorage.getItem("client_id"),
          endLocation,
          email,
          customerName,
          diraction,
          inPutlist,
          despatchLocation: sessionStorage.getItem("location"),
        })
        .then((res) => {
          if (res.data.status === 200) {
            setOpen5(false);
            bidSuccessFullySend();
            setBidsWait3(false);
          }
        });
    }
  };

  const [aadharStatus, setAadharStatus] = useState();
  const [isAddGst, setIsAddGst] = useState();

  const isKyc = () => {
    const email = sessionStorage.getItem("email");
    const type = "Customer";

    // https://pmapi.emedha.in/users/kyc/status
    axios
      .post("https://pmapi.emedha.in/users/kyc/status", {
        email,
        type,
      })
      .then((res) => {
        setAadharStatus(res.data.isAadhar);
        setIsAddGst(res.data.isAddGst);
        if (res.data.isAadhar === 1 && res.data.isAddGst === 1) {
          setIsDisable(false);
        } else {
          setIsDisable(true);
        }
        if (res.data.isAddGst === 0) {
          setOpen(true);
          setKycAlert("Please Complete Your GST Kyc ");
        } else {
          if (res.data.isAadhar === 0) {
            setOpen(true);
            setKycAlert("Please Complete Your Aadhar Kyc");
          }
        }
      });
  };

  useEffect(() => {
    isKyc();
  }, []);

  const [vehicles, setVehicles] = useState([]);

  const vehicleTypes = (vehicle, index) => {
    axios.post("https://pmapi.emedha.in/vehicle/data/type").then((res) => {
      setVehicles(res.data.result);
    });
  };

  useEffect(() => {
    vehicleTypes();
  }, []);

  const [vehicleImage, setVehicleImage] = useState(
    "https://static.vecteezy.com/system/resources/previews/006/713/303/original/truck-icon-isolated-on-a-white-background-delivery-truck-symbol-design-for-web-and-mobile-app-line-sign-free-vector.jpg"
  );

  const selectVehicleWithCapacity = (flag) => {
    calculateRoute();
    setIsBackDrop(true);
    setVehicle(flag);
    axios
      .post("https://pmapi.emedha.in/vehicle/data/capacity", {
        vehicle: flag,
      })
      .then((res) => {
        setIsCapacity(true);
        setCapacity(res.data.result);
        setIsBackDrop(false);
      });
  };

  const createBidsFormValidation = (index) => {
    bidsConfirmationAlert(startLocation, endLocation, index);
  };

  const [kycAlert, setKycAlert] = useState();

  const firstKycToSendBids = () => {
    if (isAddGst === 0) {
      setOpen(true);
      setKycAlert("Please Complete Your GST Kyc ");
    } else {
      if (aadharStatus === 0) {
        setOpen(true);
        setKycAlert("Please Complete Your Aadhar Kyc");
      }
    }
  };

  useEffect(() => {
    firstKycToSendBids();
  }, []);

  const [isDisable, setIsDisable] = useState("");

  useEffect(() => {
    if (aadharStatus === 1 && isAddGst === 1) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, []);

  const [wait3, setWait3] = useState(false);

  const locationInputValidation = () => {
    setWait3(true);
    if (originRef.current.value === "" || destiationRef.current.value === "") {
      setOpen2(true);
    } else {
      setSatrtLocation(originRef.current.value);
      setEndLocation(destiationRef.current.value);
      calculateRoute();
      setIslocation(false);
    }
    setTimeout(() => {
      setWait3(false);
    }, 1000);
  };

  const [isLocation, setIslocation] = useState(true);

  const policyValidation = () => {
    sessionStorage.setItem("isPolicy", "fdhguiurnxmvjfghhdfgh");
    setIsPolicy(true);
  };

  const [isPolicy, setIsPolicy] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("isPolicy")) {
      setIsPolicy(true);
    } else {
      setIsPolicy(false);
    }
  }, []);

  let newDate = new Date();

  const filterTime = (time, i) => {
    alert(time.target.value);

    let slots = [
      {
        start: new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          newDate.getHours() + 6,
          newDate.getMinutes() - 5
        ),
        end: new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          newDate.getHours() + 24,
          newDate.getMinutes() - 5
        ),
      },
    ];

    for (let i = 0; i < slots.length; i++) {
      const e = slots[i];

      var x = moment(time.target.value),
        beforeTime = moment(e.start),
        afterTime = moment(e.end);

      if (x.isBetween(beforeTime, afterTime)) {
        onChangeHandle(time, index);
        setDispatchError(false);
      } else {
        setDispatchError(true);
      }
    }
  };

  const [inPutlist, setInputList] = useState([
    {
      vehicleType: "",
      vehicleCapacity: "",
      material: "Perishable Foods",
      vehicleNumber: "1",
      dispatchTime: "",
    },
  ]);

  const [index, setIndex] = useState(0);

  const handleaddclick = (index) => {
    if (
      inPutlist[index].vehicleType === "" ||
      inPutlist[index].vehicleCapacity === "" ||
      inPutlist[index].despatchTime === "" ||
      inPutlist[index].dispatchTime === ""
    ) {
      Swal.fire({
        title: "Warning",
        text: "Any input fileds can't be empty",
        showCancelButton: false,

        confirmButtonColor: "red",

        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          return;
        }
      });
    } else {
      setIndex(index + 1);
      const _inPutlist = [...inPutlist];
      _inPutlist.push({
        vehicleType: "",
        vehicleCapacity: "",
        material: "Perishable Foods",
        vehicleNumber: "1",
        dispatchTime: "",
      });
      setInputList(_inPutlist);
    }
  };

  const [dispatchError, setDispatchError] = useState();

  const onChangeHandle = (e, index, date, value1, value2) => {
    if (value1 === "vehicleType") {
      const list = [...inPutlist];
      list[index][value1] = value2;
      setInputList(list);
      return;
    }

    if (value1 === "vehicleCapacity") {
      const list = [...inPutlist];
      list[index][value1] = value2;
      setInputList(list);
      return;
    }

    const { name, value } = e.target;

    const list = [...inPutlist];
    list[index][name] = value;
    setInputList(list);
    return;
  };

  return isLoaded ? (
    <Grid container sx={{ mt: 4, p: 3, position: "relative" }} spacing={2}>
      <Grid item xs={12}>
        <Item>
          <div
            className="mapContainer container"
            style={{ overflowY: !isLocation ? "scroll" : "" }}
          >
            {!isLocation && (
              <FaCaretLeft
                style={{ marginBottom: 40, marginLeft: -11, cursor: "pointer" }}
                size={30}
                onClick={() => {
                  setDiraction(0);
                  setIslocation(true);
                }}
              />
            )}
            {isLocation && !open2 && (
              <div
                style={{ position: "relative", top: "-0.5rem", left: "0.1rem" }}
              >
                <h4>Select Location</h4>
                <p>Select location to send your bids requist</p>
              </div>
            )}
            <div>
              <Box sx={{ width: "100%" }}>
                <Collapse in={open}>
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    {kycAlert}
                  </Alert>
                </Collapse>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Collapse in={open2}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen2(false);
                        }}
                        style={{ height: "2rem" }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    <span style={{ fontSize: "1.1rem" }}>
                      Fields can't be empty, Please select
                    </span>
                  </Alert>
                </Collapse>
              </Box>
            </div>
            {isBackDrop && <SimpleBackdrop />}
            {!isLocation && (
              <div>
                {
                  <div className="row">
                    <div>
                      <div
                        className="col-11 col-md-9 col-lg-8 col-xl-12 mx-auto"
                        style={{ borderRadius: "0.2rem" }}
                      >
                        <div style={{ marginLeft: 13 }}>
                          <h3>Send Bids</h3>
                          <p>
                            send bids to get more loads and improve your
                            business
                          </p>
                        </div>
                        <h5 className="text-start">
                          <span
                            style={{
                              fontWeight: "bold",
                              marginLeft: 13,
                              marginBottom: 18,
                            }}
                          >
                            Distance
                          </span>
                          : {diraction}
                        </h5>
                        {inPutlist.map((data, index) => {
                          return (
                            <div>
                              <div
                                style={{
                                  padding: "0.8rem",
                                  display:
                                    index + 1 === inPutlist.length
                                      ? ""
                                      : "none",
                                }}
                              >
                                <div
                                  className="col-11 col-md-9 col-lg-8 col-xl-12 mx-auto"
                                  style={{
                                    padding: 2,
                                    borderRadius: "0.2rem",
                                    position: "relative",
                                  }}
                                >
                                  <div className="d-block d-md-flex">
                                    <div
                                      className="me-1"
                                      style={{ display: "flex" }}
                                    >
                                      <div style={{ width: "27rem" }}>
                                        <div
                                          id="fade-button"
                                          className="card"
                                          aria-controls={
                                            open6 ? "fade-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            open6 ? "true" : undefined
                                          }
                                          onClick={handleClick1}
                                          variant="contained"
                                          style={{
                                            width: "100%",
                                            backgroundColor: "white",
                                            color: "red",
                                            border: "1px solid lightgray",
                                            height: "3rem",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            paddingRight: 15,
                                            paddingLeft: 15,
                                            background: "rgb(239, 238, 238)",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography
                                            style={{ color: "#535353" }}
                                          >
                                            <FaTruck
                                              style={{
                                                color: "gray",
                                                marginRight: 10,
                                                marginTop: "-0.2rem",
                                                fontSize: 26,
                                              }}
                                            />{" "}
                                            {vehicle}
                                          </Typography>
                                          <FaCaretDown
                                            style={{
                                              color: "#535353",
                                              fontSize: 21,
                                            }}
                                          />
                                        </div>
                                        <Menu
                                          id="fade-menu"
                                          MenuListProps={{
                                            "aria-labelledby": "fade-button",
                                          }}
                                          anchorEl={anchorEl1}
                                          open={open6}
                                          onClose={handleClose1}
                                          TransitionComponent={Fade}
                                        >
                                          <div style={{ width: "29%" }}>
                                            <div style={{ width: "32rem" }}>
                                              <div className="container">
                                                <div className="row gy-2">
                                                  {vehicles.map((data) => {
                                                    return (
                                                      <div
                                                        onClick={() => {
                                                          setVehicleImage(
                                                            data.image
                                                          );
                                                          selectVehicleWithCapacity(
                                                            data.VehicleName
                                                          );
                                                          onChangeHandle(
                                                            "_",
                                                            index,
                                                            "_",
                                                            "vehicleType",
                                                            data.VehicleName
                                                          );
                                                          setVehicle(
                                                            data.VehicleName
                                                          );
                                                          handleClose1();
                                                        }}
                                                        className="col-4 mx-auto card mt-3"
                                                        style={{
                                                          margin: 5,
                                                          alignItems: "center",
                                                          border: "none",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            width: "80%",
                                                            height: "6rem",
                                                            border:
                                                              "2px solid lightgray",
                                                            borderRadius:
                                                              "0.2rem",
                                                            position:
                                                              "relative",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          <center>
                                                            <img
                                                              src={data.image}
                                                              style={{
                                                                width:
                                                                  data.width +
                                                                  "px",
                                                              }}
                                                            />
                                                          </center>
                                                          <div
                                                            className="card"
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              bottom: 3,
                                                              left: 2,
                                                              right: 2,
                                                              backgroundColor:
                                                                "lightgray",
                                                              height: "2rem",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              border: "none",
                                                              borderRadius:
                                                                "0.2rem",
                                                            }}
                                                          >
                                                            <Typography
                                                              style={{
                                                                color:
                                                                  "#535353",
                                                                fontSize: 12,
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {data.VehicleName}
                                                            </Typography>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Menu>
                                      </div>

                                      <div style={{ width: "26rem" }}>
                                        <div
                                          id="fade-button"
                                          className="card"
                                          aria-controls={
                                            open7 ? "fade-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            open7 ? "true" : undefined
                                          }
                                          onClick={handleClick2}
                                          variant="contained"
                                          style={{
                                            width: "100%",
                                            backgroundColor: "white",
                                            color: "red",
                                            border: "1px solid lightgray",
                                            height: "3rem",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            paddingRight: 15,
                                            paddingLeft: 15,
                                            background: "rgb(239, 238, 238)",
                                            marginLeft: 7,
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              color: "#535353",
                                              fontSize: 12,
                                            }}
                                          >
                                            <FaTruck
                                              style={{
                                                color: "gray",
                                                marginRight: 10,
                                                marginTop: "-0.2rem",
                                                fontSize: 26,
                                              }}
                                            />{" "}
                                            {vehicleCapacity}
                                          </Typography>
                                          <FaCaretDown
                                            style={{
                                              color: "#535353",
                                              fontSize: 21,
                                            }}
                                          />
                                        </div>
                                        <Menu
                                          id="fade-menu"
                                          MenuListProps={{
                                            "aria-labelledby": "fade-button",
                                          }}
                                          anchorEl={anchorEl2}
                                          open={open7}
                                          onClose={handleClose2}
                                          TransitionComponent={Fade}
                                        >
                                          <div>
                                            <div className="container">
                                              <div
                                                className="row gy-2"
                                                style={{ width: "26rem" }}
                                              >
                                                {capacity.map((data) => {
                                                  return (
                                                    <div
                                                      onClick={() => {
                                                        onChangeHandle(
                                                          "_",
                                                          index,
                                                          "_",
                                                          "vehicleCapacity",
                                                          data.Capacity
                                                        );
                                                        setVehicleCapacity(
                                                          data.Capacity
                                                        );
                                                        handleClose2();
                                                      }}
                                                      className="col-12 mx-auto card mt-3"
                                                      style={{
                                                        alignItems: "center",
                                                        border: "none",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          border:
                                                            "2px solid lightgray",
                                                          borderRadius:
                                                            "0.2rem",
                                                          position: "relative",
                                                          cursor: "pointer",
                                                          padding: "0.2rem",
                                                        }}
                                                      >
                                                        <div
                                                          className="card"
                                                          style={{
                                                            backgroundColor:
                                                              "lightgray",
                                                            height: "2rem",
                                                            justifyContent:
                                                              "space-between",
                                                            alignItems:
                                                              "center",
                                                            border: "none",
                                                            borderRadius:
                                                              "0.2rem",
                                                            flexDirection:
                                                              "row",
                                                            paddingRight: 10,
                                                            paddingLeft: 10,
                                                            width: "24.9rem",
                                                          }}
                                                        >
                                                          <Typography
                                                            style={{
                                                              color: "#535353",
                                                              fontSize: 11,
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            {data.Capacity}
                                                          </Typography>
                                                          <FaCircle color="white"></FaCircle>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </Menu>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      width: "97%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ width: "49.5%" }}>
                                      <label>Product Type</label>
                                      <select
                                        name="material"
                                        disabled={isDisable}
                                        className="signup-input-field"
                                        onChange={(e, i) => {
                                          onChangeHandle(e, index);
                                        }}
                                        placeholder="Location"
                                      >
                                        <option>Perishable Foods</option>
                                        <option>Non-Perishable Foods</option>
                                        <option>Agri Products</option>
                                        <option>Other</option>
                                      </select>
                                    </div>

                                    <div style={{ width: "24.2%" }}>
                                      <label>Product Name</label>
                                      <input
                                        name="material"
                                        disabled={isDisable}
                                        className="signup-input-field"
                                        onChange={(e, i) => {
                                          onChangeHandle(e, index);
                                        }}
                                        placeholder="Product name"
                                      />
                                    </div>

                                    <div style={{ width: "24.2%" }}>
                                      <label>Product in ton</label>
                                      <input
                                        name="material"
                                        type="number"
                                        disabled={isDisable}
                                        className="signup-input-field"
                                        onChange={(e, i) => {
                                          onChangeHandle(e, index);
                                        }}
                                        placeholder="ton"
                                      />
                                    </div>
                                  </div>

                                  <div style={{ width: "74.4%" }}>
                                    <label>Number Of Vehicle</label>
                                    <select
                                      name="vehicleNumber"
                                      disabled={isDisable}
                                      className="signup-input-field"
                                      onChange={(e, i) => {
                                        onChangeHandle(e, index);
                                      }}
                                      placeholder="Location"
                                    >
                                      <option value={1}>One Vehicle</option>
                                      <option value={2}>Two Vehicle</option>
                                      <option value={3}>Three Vehicle</option>
                                      <option value={4}>Four Vehicle</option>
                                    </select>
                                  </div>
                                  <br />

                                  <div style={{ width: "13rem" }}>
                                    <Typography style={{ fontSize: 14 }}>
                                      Select Despatch Time
                                    </Typography>
                                    <input
                                      style={{ width: "16rem" }}
                                      onChange={(e, i) => {
                                        filterTime(e, i);
                                      }}
                                      type="datetime-local"
                                      name="dispatchTime"
                                    />
                                  </div>
                                  <Typography
                                    style={{ fontSize: 12, marginTop: 12 }}
                                  >
                                    Your current time: {newDate.getDate()}/
                                    {newDate.getMonth() + 1}/
                                    {newDate.getFullYear()} T{" "}
                                    {newDate.getHours()}:{newDate.getMinutes()}
                                  </Typography>
                                  <Typography>
                                    Your despatch time:{" "}
                                    {inPutlist[index].dispatchTime === ""
                                      ? "00/00/0000 T 00:00"
                                      : moment(
                                          inPutlist[index].dispatchTime
                                        ).date() +
                                        "/" +
                                        (moment(
                                          inPutlist[index].dispatchTime
                                        ).month() +
                                          1) +
                                        "/" +
                                        moment(
                                          inPutlist[index].dispatchTime
                                        ).year() +
                                        " " +
                                        "T" +
                                        " " +
                                        (moment(
                                          inPutlist[index].dispatchTime
                                        ).hour() < 12
                                          ? moment(
                                              inPutlist[index].dispatchTime
                                            ).hour()
                                          : moment(
                                              inPutlist[index].dispatchTime
                                            ).hour() - 12) +
                                        ":" +
                                        moment(
                                          inPutlist[index].dispatchTime
                                        ).minute()}{" "}
                                    <Typography
                                      style={{ fontSize: 12, color: "red" }}
                                    >
                                      {" "}
                                      {dispatchError &&
                                        "Your despatch time should be 6 Hrs more than the current time (Max despatch time 24 Hrs from your current time)"}{" "}
                                    </Typography>
                                  </Typography>
                                  <div
                                    className="d-flax"
                                    style={{
                                      position: "absolute",
                                      right: "0.3rem",
                                      top: 4.5,
                                    }}
                                  >
                                    {/* {
                                                                                    inPutlist.length !== 1 && (
                                                                                        <Button onClick={() => handleRemove(index)} variant="contained" style={{ backgroundColor: 'red', padding: '0.6rem', width: '5rem', height: '2.1rem', }}>Remove</Button>
                                                                                    )
                                                                                } */}
                                    {inPutlist.length - 1 === index && (
                                      <Button
                                        onClick={() => {
                                          handleaddclick(index);
                                          setVehicleCapacity(
                                            "Select Vehicle Capacity"
                                          );
                                          setVehicle("Select Vehicle Type");
                                        }}
                                        variant="contained"
                                        style={{
                                          backgroundColor: "#12124e",
                                          padding: "0.6rem",
                                          width: "5rem",
                                          height: "2.1rem",
                                          marginLeft: 2,
                                        }}
                                      >
                                        + Add
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div>
                      <Dialog
                        fullScreen={fullScreen}
                        open={open5}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth="lg"
                      >
                        <DialogTitle id="responsive-dialog-title">
                          {"Your All Bids"}
                        </DialogTitle>

                        <DialogContent>
                          <DialogContentText
                            style={{ width: "53rem", height: "32rem" }}
                          >
                            {inPutlist.map((data) => {
                              return (
                                <Box
                                  sx={{
                                    padding: 1,
                                    backgroundColor: "rgb(225, 174, 78)",
                                    borderRadius: "0.01rem",
                                    marginBottom: 6,
                                    borderLeft: "3px solid #020222",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      color: "white",
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Despatch Location:{" "}
                                    {sessionStorage.getItem("location")}
                                  </Typography>
                                  <Typography
                                    style={{ color: "white", fontSize: 13 }}
                                  >
                                    Vehicle Type: {data.vehicleType}
                                  </Typography>
                                  <Typography
                                    style={{ color: "white", fontSize: 13 }}
                                  >
                                    Vehicle Capacity: {data.vehicleCapacity}
                                  </Typography>
                                  <Typography
                                    style={{ color: "white", fontSize: 13 }}
                                  >
                                    Material: {data.material}
                                  </Typography>
                                  <Typography
                                    style={{ color: "white", fontSize: 13 }}
                                  >
                                    Number Of Vehicle: {data.vehicleNumber}{" "}
                                    {data.vehicleNumber === "1"
                                      ? "Vehicle"
                                      : "Vehicles"}
                                  </Typography>
                                  <Typography
                                    style={{ color: "white", fontSize: 13 }}
                                  >
                                    Despatch Time:{" "}
                                    {moment(data.dispatchTime).date()}/
                                    {moment(data.dispatchTime).month() + 1}/
                                    {moment(data.dispatchTime).year()} T{" "}
                                    {moment(data.dispatchTime).hour() < 12
                                      ? moment(data.dispatchTime).hour()
                                      : moment(data.dispatchTime).hour() - 12}
                                    :{moment(data.dispatchTime).minute()}
                                  </Typography>
                                  <div
                                    className="card p-2 shadow"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      borderRadius: "0.2rem",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      position: "relative",
                                      top: "2.1rem",
                                      marginTop: "-1.7rem",
                                    }}
                                  >
                                    <Typography
                                      style={{ width: "49%", fontSize: 12 }}
                                    >
                                      <FaMapMarkerAlt
                                        style={{
                                          color: "gold",
                                          fontSize: 14,
                                          marginTop: "-0.2rem",
                                        }}
                                      />{" "}
                                      From: {startLocation}
                                    </Typography>{" "}
                                    <Typography
                                      style={{ width: "49%", fontSize: 12 }}
                                    >
                                      <FaCircle
                                        style={{
                                          color: "red",
                                          fontSize: 14,
                                          marginTop: -"0.3rem",
                                        }}
                                      />{" "}
                                      To: {endLocation}
                                    </Typography>
                                  </div>
                                </Box>
                              );
                            })}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <div className="d-flex">
                            <div>
                              <Button
                                onClick={() => setOpen5(false)}
                                variant="contained"
                                style={{
                                  backgroundColor: "red",
                                  width: "7rem",
                                  fontSize: "0.64rem",
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                            {aadharStatus === 1 &&
                              isAddGst === 1 &&
                              !bidsWait3 && (
                                <div>
                                  <Button
                                    onClick={() =>
                                      createBidsFormValidation(index)
                                    }
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#12124e",
                                      width: "7rem",
                                      fontSize: "0.64rem",
                                      marginLeft: 2,
                                    }}
                                  >
                                    Send Request
                                  </Button>
                                </div>
                              )}
                            {bidsWait3 && (
                              <div>
                                <Button
                                  disabled={true}
                                  onClick={createBidsFormValidation}
                                  variant="contained"
                                  style={{ width: "7rem" }}
                                >
                                  <img
                                    src={loading}
                                    style={{
                                      height: 20,
                                      width: 20,
                                      color: "lightgray",
                                      marginLeft: 2,
                                      fontSize: "0.64rem",
                                    }}
                                  />{" "}
                                  Sending...
                                </Button>
                              </div>
                            )}
                          </div>
                        </DialogActions>
                      </Dialog>
                    </div>
                    {(aadharStatus !== 1 || isAddGst !== 1) && (
                      <div>
                        <Button
                          onClick={() => firstKycToSendBids()}
                          className="shake"
                          variant="contained"
                          style={{
                            backgroundColor: "#12124e",
                            padding: "0.6rem",
                            width: "10rem",
                          }}
                        >
                          Send Request
                        </Button>
                      </div>
                    )}

                    {aadharStatus === 1 && isAddGst === 1 && !bidsWait3 && (
                      <div>
                        <Button
                          onClick={() => setOpen5(true)}
                          variant="contained"
                          style={{
                            backgroundColor: "#12124e",
                            padding: "0.4rem",
                            width: "8rem",
                            position: "relative",
                            left: 27,
                            top: 10,
                          }}
                        >
                          Confirm
                        </Button>
                      </div>
                    )}

                    <br />
                    <br />
                  </div>
                }
              </div>
            )}

            {isLocation && (
              <div>
                <div className="durationAndDistance">
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#1c1c5c",
                      paddingTop: 6,
                      paddingBottom: 7,
                    }}
                  >
                    <div
                      style={{ width: "100%", position: "relative", left: 34 }}
                    >
                      <Autocomplete>
                        <input
                          disabled={isDisable}
                          className="location"
                          ref={originRef}
                          placeholder="Origin"
                        />
                      </Autocomplete>
                    </div>
                    <br />
                    <PlaceIcon
                      style={{
                        position: "absolute",
                        top: "1.4rem",
                        left: "1.1rem",
                        color: "gold",
                      }}
                    />
                    <FiberManualRecordIcon
                      style={{
                        position: "absolute",
                        top: "5.3rem",
                        left: "1.1rem",
                        color: "white",
                      }}
                    />
                    <div
                      style={{
                        height: "2.8rem",
                        borderLeft: "3px solid gray",
                        position: "absolute",
                        top: "2.6rem",
                        left: "1.8rem",
                      }}
                    ></div>
                    <div
                      style={{ width: "100%", position: "relative", left: 34 }}
                    >
                      <Autocomplete>
                        <input
                          disabled={isDisable}
                          className="location"
                          ref={destiationRef}
                          placeholder="Destination"
                        />
                      </Autocomplete>
                    </div>

                    <br />

                    <button
                      style={{
                        height: "2.4rem",
                        width: "7rem",
                        backgroundColor: "white",
                        border: "none",
                        color: "#1c1c5c",
                        marginLeft: 33,
                        marginTop: 7,
                        borderRadius: 3,
                        fontWeight: "bold",
                      }}
                      onClick={() => locationInputValidation()}
                    >
                      Next
                    </button>
                    <button
                      style={{
                        height: "2.4rem",
                        width: "7rem",
                        backgroundColor: "white",
                        border: "none",
                        color: "#1c1c5c",
                        marginLeft: 33,
                        marginTop: 7,
                        borderRadius: 3,
                        fontWeight: "bold",
                      }}
                      onClick={() => calculateRoute()}
                    >
                      Route
                    </button>
                  </div>

                  <div
                    className="d-flex"
                    style={{ width: "100%", marginTop: 10 }}
                  >
                    <div style={{ width: "100%" }}>
                      <h5 className="text-center">
                        <span style={{ fontWeight: "bold" }}>Distance</span>:{" "}
                        {diraction} Km
                      </h5>
                    </div>
                  </div>
                </div>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={20}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  <Marker position={center} />
                  {diractionResponce && (
                    <DirectionsRenderer directions={diractionResponce} />
                  )}
                </GoogleMap>
              </div>
            )}
          </div>
        </Item>
      </Grid>
      {wait3 && <SimpleBackdrop />}

      {(!sessionStorage.getItem("isPolicy") || !isPolicy) && (
        <div
          className="card shadow"
          style={{
            position: "fixed",
            left: "13rem",
            right: "13rem",
            bottom: "0.2rem",
            backgroundColor: "white",
            zIndex: 100002,
            border: "none",
            borderRadius: "0.3rem",
            padding: 17,
          }}
        >
          <Typography>
            We use cookies to ensure you have the best browsing experience on
            our website. By using our site, you acknowledge that you have read
            and understood our{" "}
            <span style={{ textDecoration: "underline", color: "blueviolet" }}>
              Cookie Policy
            </span>{" "}
            &{" "}
            <span style={{ textDecoration: "underline", color: "blueviolet" }}>
              Privacy Policy
            </span>{" "}
          </Typography>
          <Button
            style={{
              position: "absolute",
              right: 83,
              color: "red",
              bottom: 10,
              width: "7rem",
            }}
            onClick={() => window.location.reload()}
          >
            Cancel
          </Button>
          <Button
            style={{
              position: "absolute",
              right: 4,
              color: "#0d0d4a",
              bottom: 10,
              width: "7rem",
            }}
            onClick={() => policyValidation()}
          >
            Accept
          </Button>
        </div>
      )}
      {(!sessionStorage.getItem("isPolicy") || !isPolicy) && (
        <div
          className="card"
          style={{
            position: "fixed",
            left: "0rem",
            right: "0rem",
            bottom: "0rem",
            backgroundColor: "black",
            top: 0,
            opacity: "0.5",
            zIndex: 100000,
          }}
        ></div>
      )}
    </Grid>
  ) : (
    <>
      <Skeleton />
    </>
  );
};

export default CreateBids;
