import React, { useEffect, useState } from 'react';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './idex.css'; // Import custom CSS for animation

import Header from '../LandingPages/Header/Header';
import Footer from '../LandingPages/Footer/Footer';

const PaymentStatus = () => {
  const [paymentId, setPaymentId] = useState(null);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(null);
  const [userId, setUserId] = useState(null);
  const[remainMoney,setRMoney]=useState(null)
  const [productId, setProductId] = useState(null);

  const percentage = 90; // Given percentage
const remainMoneyAmount = remainMoney; // Given amount which is 90%

// Calculate full amount
const fullAmount = remainMoneyAmount / (percentage / 100);

// Calculate 10% of the full amount
const tenPercentAmount = (fullAmount * 0.1).toString();



  useEffect(() => {
    const url = window.location.pathname;
    const segments = url.split('/');
    
    const trid = segments[segments.length - 5]; // Transaction ID
    const mt = segments[segments.length - 4];  // Token
    const clientId = segments[segments.length - 3]; // User ID
    const bId = segments[segments.length - 2]; // Product ID
    const rMoney = segments[segments.length - 1]; // Remaining Money
  
    setPaymentId(trid);
    setToken(mt);
    setUserId(clientId);
    setProductId(bId);
    setRMoney(rMoney);
    const brokerClientId=localStorage.getItem('client_id')

    console.log(brokerClientId)
  
    async function fetchStatus() {
      try {
        const response = await fetch(`https://pmapi.emedha.in/payment/prime/pg/v1/payment-status/${trid}/${mt}/${clientId}/${bId}/${rMoney}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            trid,
            mt,
            clientId,
            bId,
            rMoney,
          }),
        });
  
        const result = await response.text();
        setStatus(result);
  
        // Check if payment was successful
        if (result.includes('Payment Successful')) {
          setIsSuccessful(true);
          // Call the successful API
          // await handleSuccessfulPayment();
        } else {
          setIsSuccessful(false);
        }
  
      } catch (error) {
        console.error('Error fetching payment status:', error);
        setStatus('Error fetching payment status');
        setIsSuccessful(false);
      } finally {
        setLoading(false);
      }
    }

  
    fetchStatus();
  }, []);
  


  if (loading) {
    return <div>Loading...</div>;
  }



  return (
    <>
      <Header />
      <div className="container mt-4">
        <div className="card-status">
        <p className="text-center"><strong>Transaction ID:</strong> {paymentId}</p> {/* Display the transaction ID */}
        <p className="text-center"><strong>BID ID:</strong> {productId}</p> 
        <div className="text-center" dangerouslySetInnerHTML={{ __html: status.message }}></div>
          {isSuccessful === true && (
            <div className="card-io card-success">
              <div className="card-body">
                <FaCheckCircle className="icon-success" />
                <h5 className="card-title">Payment Successful</h5>
                <p className="card-text">Transaction was completed successfully.</p>
                <p className="text-center"><strong> Remaining Balance :</strong> {remainMoney}</p> 
              </div>
            </div>
          )}
          {isSuccessful === false && (
            <div className="card-io card-failure">
              <div className="card-body">
                <FaTimesCircle className="icon-failure" />
                <h5 className="card-title">Payment Failed</h5>
                <p className="card-text">There was an issue with the transaction.</p>
              </div>
            </div>
          )}
           {isSuccessful === null && (
            <div className="card card-info">
              <div className="card-body">
                <h5 className="card-title">Status</h5>
                <p className="card-text">{status}</p> {/* Display the status text */}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentStatus;
