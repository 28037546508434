import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import { FaSearch, FaWindowClose } from 'react-icons/fa';
import Menu from '@mui/material/Menu';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Fade from '@mui/material/Fade';
import { AppBar, List, TextField } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';

import DialogContentText from '@mui/material/DialogContentText';

import { FaCheckCircle } from 'react-icons/fa';

import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import SimpleBackdrop from '../../../BackDrop/BackDrop';

const Transition5 = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function TransporterConfirmedBids() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'auto',
        color: theme.palette.text.secondary,
    }));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [open5, setOpen5] = React.useState(false);

    const handleClickOpen5 = (bidpoint, veh, driver, id) => {
        console.log(id)
        setBidId(id)
        transPersonalData(bidpoint, veh, driver)
    };

    const [money, setMoney] = React.useState([])
    console.log(money)
    const [notifyLen, setNotifyLen] = React.useState()


    const acceptedBidsPrice = () => {
        const email = sessionStorage.getItem('email')
        const show = 'accept'
        const isNotification = 0
        axios.post('https://pmapi.emedha.in/send/bids/top-three/price', {
            email,
            show,
            isNotification
        }).then((res) => {
            setMoney(res.data.result)
            setNotifyLen(res.data.result.length)
            setIsFilter(false)
        })
    }

    useEffect(() => {
        acceptedBidsPrice()
    }, [])


    const handleClose5 = () => {
        setOpen5(false);
    };

    const [personalData, setPersonalData] = useState([])
    const [busines, setBusiness] = useState([])
    const [bidId, setBidId] = useState()
    const [vehicle, setVehicle] = useState([])
    const [driver, setDriver] = useState([])

    const transPersonalData = (client_id, vehicleId, driverId) => {
        axios.post('https://pmapi.emedha.in/send/bids/proceed', {
            client_id
        }).then((res) => {
            setPersonalData(res.data.data)
            setBusiness(res.data.data)
            conf_veh(bidId,vehicleId, driverId)
        })
    }

//    https://pmapi.emedha.in/vehicle/data/show-to-confirm

console.log(bidId)


    // const conf_veh = (vehicleId, driverId) => {
    //     axios.post('https://pmapi.emedha.in/vehicle/data/show-to-confirm', {
    //         send_bids_id:bidId
    //     }).then((res) => {
    //         setVehicle(res.data.data)
    //         conf_driver(driverId)
    //     })
    // }

    const conf_veh = (bidId, vehicleId, driverId) => {
        console.log('Bid ID before API call:', bidId); // Check the value of bidId
    
        axios.post('https://pmapi.emedha.in/vehicle/data/show-to-confirm', {
            send_bids_id: bidId // This should be set correctly
        })
        .then((res) => {
            setVehicle(res.data.data);
            conf_driver(driverId);
        })
        .catch((error) => {
            console.error('API call error:', error);
        });
    };
    

    const conf_driver = (driverId) => {
        axios.post('https://pmapi.emedha.in/driver/data/show-to-confirm', {
            driverId
        }).then((res) => {
            setDriver(res.data.data)
            setOpen5(true)
        })
    }


    const [value1, setValue1] = React.useState('1');

    const handleChange6 = (event, newValue) => {
        setValue1(newValue);
    };


    const [open3, setOpen3] = useState(false)
    const [rMoney, setRMoney] = useState('')

    console.log(rMoney)

    const loadConfirm = () => {

        axios.post('https://pmapi.emedha.in/payment/get-90pre/payment', {
            bidId
        }).then((res) => {
            setRMoney(res.data.money)
            setOpen18(true)
        })
    }

    const [loadingImage, setLoadingImage] = useState('')
    const [loadingDate, setLoadingDate] = useState('')
    const [loadingFieldDataError, setLoadingFieldDataError] = useState(false)

    const uploadLoadingImage = () => {

        if (loadingDate === '' && loadingImage === '') {
            setLoadingFieldDataError(true)
            return
        }

        const formData = new FormData()

        const client_id = sessionStorage.getItem('client_id')



        formData.append('file', loadingImage)
        formData.append('bidId', bidId)
        formData.append('client_id', client_id)
        formData.append('loadingDate', loadingDate)

        axios.post('https://pmapi.emedha.in/vehicle/data/upload-loading-image', formData).then((res) => {
            if (res.data.status === 200) {
                setOpen3(true)
                setOpen18(false)
            }
        })

    }

    // const payment90Pre = () => {
        // const sendBidsNumber = sessionStorage.getItem('bidSendNum') - 1
        // const client_id = sessionStorage.getItem('client_id')
    //     axios.post('https://pmapi.emedha.in/payment/90pre/payment', {
            // bidId,
            // sendBidsNumber,
            // client_id
    //     }).then((res) => {
            // setOpen3(false)
            // setOpen5(false)
            // setOpen18(false)
            // acceptedBidsPrice()
    //         Swal.fire({
    //             title: "Successful..!",
    //             text: "Payment successfully sended ...!",
    //             confirmButtonColor: "#3085d6",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: "Dashboard",
    //         }).then((result) => {
    //             if (result.value) {
    //                 setOpen3(false)
    //                 setOpen5(false)
    //             }
    //         });

    //     })
    // }


    const [payUrl, setPayUrl] = useState('');
    const [isPayConfirm, setIsPayConfirm] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [loading, setLoading] = useState(false);
   
    const payment90Pre = () => {
        if (loading) return; // Prevent multiple requests if already loading  /initiate-payment
        setLoading(true);
    
        // https://pmapi.emedha.in/payment/pay/v1/pay
        const sendBidsNumber = sessionStorage.getItem('bidSendNum') - 1
        const client_id = sessionStorage.getItem('client_id')
    
        axios.post('https://pmapi.emedha.in/payment/90pre/v1/pay', {
            payText:rMoney,
            bidId,
            sendBidsNumber,
            client_id
        })
          .then((res) => {
            if (res.data.success) {
              setPayUrl(res.data.url);
              window.open(res.data.url);
              setIsPayConfirm(true);
              setOpen3(false)
              setOpen5(false)
              setOpen18(false)
              acceptedBidsPrice()
            //   bidSuccessFullySend()
            } else {
              console.error('API Response Error:', res.data.message || 'Unknown error');
              alert('Something went wrong');
            }
          })
          .catch((error) => {
            console.error('API Request Error:', error);
            setOpen4(false);
            alert('Payment Error');
          })
          .finally(() => {
            setLoading(false); 
          });
    
  
    }



    const [wait14, setWait14] = useState(false)
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')

    const [isFilter, setIsFilter] = useState(false)

    const filterByRoute = () => {
        handleClose()
        setWait14(true)
        axios.post('https://pmapi.emedha.in/send/bids/filter-by-route-notification', {
            from,
            to
        }).then((res) => {
            setTimeout(() => {
                setWait14(false)
                setMoney(res.data.notify)
                setNotifyLen(res.data.notify.length)
                setIsFilter(true)
            }, 650)
        })
    }

    const searchByName = (name) => {

        axios.post('https://pmapi.emedha.in/send/bids/search-by-name-notification', {
            name,
            email: sessionStorage.getItem('email')
        }).then((res) => {
            setMoney(res.data.notify)
            setNotifyLen(res.data.notify.length)
        })
    }

    const [isSearch, setIsSeacrh] = useState(false)

    const [open18, setOpen18] = React.useState(false);



    return (
        <div>
            <Box sx={{ flexGrow: 1, mt: 7 }}>
                {
                    isSearch && (
                        <div style={{ position: 'relative', width: '35rem' }} >
                            <FaSearch style={{ color: 'gray', position: 'absolute', right: 8, top: 14 }} />
                            <input autoFocus className='shadow' style={{ paddingLeft: 10, height: '2.8rem', width: '35rem', borderRadius: '0.2rem', border: 'none', outline: 'none', backgroundColor: 'shyblue' }} placeholder='Search notification' onChange={(e) => searchByName(e.target.value)} />
                        </div>
                    )
                }
                <Grid container spacing={2}>
                    <Grid item sx={{ position: 'relative' }} xs={5}>
                        {
                            !isSearch && (
                                <Box>
                                    <FaSearch style={{ position: 'absolute', left: 19, top: '2rem', fontSize: 17, color: 'gray', borderRadius: '0.3rem', zIndex: 1000 }} />
                                    <Item style={{ height: '3rem', position: 'relative', backgroundColor: 'shyblue' }}>

                                        <input style={{ width: '100%', height: '100%', paddingLeft: 17, border: 'none', outline: 'none', backgroundColor: 'shyblue' }} placeholder='Search notification' onClick={() => setIsSeacrh(true)} />

                                    </Item>
                                </Box>
                            )
                        }
                        {
                            !isFilter && (
                                <TuneTwoToneIcon
                                    id="fade-button"
                                    aria-controls={open ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className='iconHover' style={{ position: 'absolute', right: 4, top: '1.5rem', fontSize: 32, color: 'gold', borderRadius: '0.3rem' }} />
                            )
                        }
                        {
                            isFilter && (
                                <FaWindowClose
                                    id="fade-button"
                                    aria-controls={open ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={acceptedBidsPrice}
                                    className='iconHover' style={{ position: 'absolute', right: 4, top: '1.5rem', fontSize: 32, color: 'gold', borderRadius: '0.3rem' }} />
                            )
                        }
                    </Grid>
                    <div>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            style={{ marginLeft: -130, marginTop: 18 }}
                        >
                            <div style={{ height: '19rem', width: '39rem', padding: 19 }} >
                                <div>
                                    <h4>Filter by route</h4>
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            id="standard-multiline-flexible"
                                            label="From"
                                            multiline
                                            maxRows={4}
                                            variant="standard"
                                            sx={{ mt: 2, width: '100%' }}
                                            onChange={(e) => setFrom(e.target.value)}
                                        />

                                        <TextField
                                            id="standard-multiline-flexible"
                                            label="To"
                                            multiline
                                            maxRows={4}
                                            variant="standard"
                                            sx={{ mt: 2, width: '100%' }}
                                            onChange={(e) => setTo(e.target.value)}

                                        />
                                        <Button style={{ position: 'absolute', bottom: '0.4rem', right: '10rem', height: '2.8rem', width: '7.4rem', border: 'none', backgroundColor: 'gold', color: 'white', fontWeight: 'bold', borderRadius: '0.2rem' }} onClick={() => filterByRoute()} >Seacrh</Button>
                                        <Button style={{ position: 'absolute', bottom: '0.4rem', right: '0.86rem', height: '2.8rem', width: '7.4rem', border: 'none', backgroundColor: 'red', color: 'white', fontWeight: 'bold', borderRadius: '0.2rem' }} onClick={() => handleClose()} >Cancel</Button>
                                    </form>
                                </div>
                            </div>
                        </Menu>
                    </div>
                </Grid>
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden', height: '77.3vh', mt: 2 }}>
                <TableContainer sx={{ maxHeight: '100%' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{ width: 230 }}
                                >
                                    Bid Id
                                </TableCell>
                                <TableCell

                                    style={{ width: 160 }}
                                >
                                    From
                                </TableCell>
                                <TableCell

                                    style={{ width: 160, align: 'center' }}
                                >
                                    To
                                </TableCell>
                                <TableCell
                                    style={{ width: 40 }}

                                >
                                    Action
                                </TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {money
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow className='TableHover' hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell width={{ width: 230 }}>
                                                {row.acceptedBids_id}
                                            </TableCell>
                                            <TableCell width={{ width: 160 }}>
                                                {row.fromRoute}
                                            </TableCell>
                                            <TableCell width={{ width: 160 }}>
                                                {row.toRoute}
                                            </TableCell>

                                            {
                                                row.is90Pre === 1 && (
                                                    <TableCell width={{ width: 40 }}>
                                                        <Button disabled={true} variant='contained'>Proceeded </Button>
                                                    </TableCell>
                                                )
                                            }

                                            {
                                                row.is90Pre === 0 && (
                                                    <TableCell onClick={() => {
                                                        handleClickOpen5(row.client_id, row.vehicleId, row.driverId, row.acceptedBids_id)
                                                    }} width={{ width: 40 }}>
                                                        <Button style={{ backgroundColor: '#282c34', fontWeight: 'bold', color: 'white' }}>Proceedss </Button>
                                                    </TableCell>
                                                )
                                            }

                                        </TableRow>
                                    );
                                })}

                        </TableBody>

                    </Table>
                </TableContainer>
                <div >
                    <Dialog
                        fullScreen
                        open={open5}
                        onClose={handleClose5}
                        TransitionComponent={Transition5}
                    >
                        <AppBar elevation={2} sx={{ position: 'relative', backgroundColor: 'white', color: 'gray' }} >
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose5}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Bid Id: {bidId}  <span style={{ color: 'lightgreen' }} > Verified <FaCheckCircle color='lightgreen' /></span>
                                </Typography>
                                <Button color="inherit" variant='outlined' onClick={() => loadConfirm()}>
                                    Loading Confirmation
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <List>
                            <ListItem>
                                <ListItemText />
                            </ListItem>
                            <Divider />
                            <ListItem >
                                <Box sx={{ flexGrow: 1, p: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid xs={12}>
                                            <Item elevation={2} sx={{ height: '74vh' }}>
                                                <Box sx={{ width: '100%', typography: 'body1', p: 2 }}>
                                                    <TabContext value={value1}>
                                                        <Box sx={{ boxShadow: '2px', p: 1 }}>
                                                            <TabList onChange={handleChange6} aria-label="lab API tabs example">
                                                                <Tab label="Personal Details" value="1" />
                                                                <Tab label="Business Details" value="2" />
                                                                <Tab label="Driver Details" value="3" />
                                                                <Tab label="Vehicle Details" value="4" />
                                                            </TabList>
                                                        </Box>
                                                        <Grid container spacing={2}>
                                                            <Grid xs={12}>
                                                                <Item elevation={0} sx={{ height: '50vh' }}>
                                                                    <TabPanel value="1">
                                                                        <Box>
                                                                            <Typography>Personal Data</Typography>
                                                                            <Divider sx={{ borderBottom: 1 }} />
                                                                            {
                                                                                personalData.map((data) => {
                                                                                    sessionStorage.setItem('bidSendNum', data.sendBidsNumber)
                                                                                    sessionStorage.setItem('client_id', data.client_id)
                                                                                    return (
                                                                                        <Box>
                                                                                            <Box sx={{ marginTop: 6 }}>
                                                                                                <Typography>Name: {data.first_name} {data.last_name}</Typography>
                                                                                                <br />
                                                                                                <Typography>Email: {data.email}</Typography>
                                                                                                <br />
                                                                                                <Typography>Mobile: {data.mobile}</Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </TabPanel>
                                                                    <TabPanel value="2">
                                                                        <Box>
                                                                            <Typography>Business Data</Typography>
                                                                            <Divider sx={{ borderBottom: 1 }} />
                                                                            {
                                                                                busines.map((data) => {
                                                                                    return (
                                                                                        <Box>

                                                                                            <Box sx={{ marginTop: 6 }}>
                                                                                                <Typography>Nature Of Business: {data.natureOfBusiness} </Typography>
                                                                                                <br />
                                                                                                <Typography>GST Number: {data.gst}</Typography>
                                                                                                <br />
                                                                                                <Typography>Firm: {data.firm}</Typography>
                                                                                                <br />
                                                                                                <Typography>Pan Card: {data.pan_card}</Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </TabPanel>
                                                                    <TabPanel value="3">
                                                                        <Box>
                                                                            <Typography>Driver Data</Typography>
                                                                            <Divider sx={{ borderBottom: 1 }} />
                                                                            {
                                                                                driver.map((data) => {
                                                                                    return (
                                                                                        <Box>
                                                                                            <Box sx={{ marginTop: 6 }}>
                                                                                                <Typography>Driver Name: {data.dName} </Typography>
                                                                                                <br />
                                                                                                <Typography>DL Number: {data.DLNumber}</Typography>
                                                                                                <br />
                                                                                                <Typography>Mobile Number: {data.dMobile}</Typography>
                                                                                                <br />
                                                                                                <Typography>Bank Upi Id: {data.bankUpi}</Typography>
                                                                                                <br />
                                                                                                <Typography>Driver Aadhar: {data.DAadhar}</Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </TabPanel>
                                                                    <TabPanel value="4">
                                                                        <Typography>Vehicle Data</Typography>
                                                                        <Divider sx={{ borderBottom: 1 }} />
                                                                        <Box>
                                                                            {
                                                                                vehicle.map((data) => {
                                                                                    return (
                                                                                        <Box>
                                                                                            <Box sx={{ marginTop: 6 }}>
                                                                                                <Typography>RC Number: {data.RegNum}</Typography>
                                                                                                <br />
                                                                                                <Typography>Vehicle: {data.vehicleType}</Typography>
                                                                                                <br />
                                                                                                <Typography>Capacity: {data.vehicleCapacity}</Typography>
                                                                                                <br />
                                                                                                <Typography>Road Permit: {data.RPermit}</Typography>
                                                                                                <br />
                                                                                                <Typography>Fitness Certificate: {data.fCertificate}</Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </TabPanel>
                                                                </Item>
                                                            </Grid>
                                                        </Grid>
                                                    </TabContext>
                                                </Box>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </ListItem>
                        </List>
                    </Dialog>
                </div>
                <div>
                    <Dialog open={open3} >
                        <DialogTitle style={{ fontSize: 20, textAlign: 'center' }} >Payment Getway</DialogTitle>
                        <DialogTitle>Pay your 90% pending amount to the your loading confirmation</DialogTitle>
                        <DialogContent>

                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Delivery Confirmation Price"
                                type="email"
                                fullWidth
                                variant="standard"
                                value={rMoney}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => payment90Pre()} >Pay</Button>
                            <Button onClick={() => setOpen3(false)}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={open18}
                        maxWidth='lg'
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Loading Confirmation"}
                        </DialogTitle>
                        <DialogContent>
                            <div className='card' style={{ width: '60rem', borderRadius: '0.2rem', padding: 9, flexDirection: 'row', justifyContent: 'space-between' }} >

                                <div style={{ width: '49%' }}>
                                    <img src="https://img.freepik.com/free-vector/vector-yellow-dump-truck-unloading-side-view-illustration-isolated-white-background_8130-972.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=ais" width='100%' alt='image' />
                                </div>

                                <div style={{ width: '40%', marginTop: 40 }}>
                                    <label>Loading date</label>
                                    <TextField
                                        margin="dense"
                                        id="name"
                                        type="date"
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => {
                                            setLoadingDate(e.target.value)
                                            setLoadingFieldDataError(false)
                                        }}
                                    />
                                    <br />
                                    <label>Loading picture</label>
                                    <TextField
                                        margin="dense"
                                        id="name"
                                        type="file"
                                        name='file'
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => {
                                            setLoadingImage(e.target.files[0])
                                            setLoadingFieldDataError(false)
                                        }}

                                    />
                                    <br />

                                    <TextField
                                        margin="dense"
                                        id="name"
                                        label="Delivery confirmation amount"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                        value={rMoney}
                                    />
                                    {
                                        loadingFieldDataError && (
                                            <Typography style={{ color: 'red', marginTop: 21 }} >Any fields can't be empty..!</Typography>
                                        )
                                    }
                                </div>

                            </div>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => setOpen18(false)} style={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>Cancel</Button>
                            <Button onClick={() => uploadLoadingImage()} style={{ backgroundColor: '#1c1c5c', color: 'white', fontWeight: 'bold' }} >
                                Pay
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                {
                    wait14 && (
                        <SimpleBackdrop />
                    )
                }
                {
                    notifyLen === 0 && (
                        <Box className='card' style={{ border: 'none', width: '100%', height: '69vh', alignItems: 'center', justifyContent: 'center', BoxShadow: 'none' }} >
                            <img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=ais' style={{ width: '130px' }} />
                            <h5>0 Notifications</h5>
                        </Box>
                    )
                }
            </Paper>
        </div>
    );
}