import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./Container/Auth/Signup/Signup/Signup";
import "./App.css";
import LorryBrokerDashboard from "./Container/dashboard/lorry_broker_dashboard/LorryBrokerDashboard";
import CostomerDashboard from "./Container/dashboard/customer_dashboard/CostomerDashboard";
import WelcomeAdmin from "./Container/AdminDashBoard/WelcomeAdmin.js/WelcomeAdmin";
import ResetPassword from "./Container/Auth/Credential/resetPassword/ResetPassword";
import UserEmail from "./Container/Auth/Credential/EmailPassword/EmailPassword";
import ChangePassword from "./Container/Auth/Credential/setPassword/ChangePassword";
import UserRegLinkVerification from "./Container/Auth/userregisterlinkverifiaction/UserRegLinkVerification";
import ForgotPassLinkVerification from "./Container/Auth/forgotpasswordlinkverification/PasswordLinkVerification";
import { UserLogin } from "./Container/Auth/userLogin/UserLogin";
import Company from "./Container/LandingPages/Company/Company";
import Products from "./Container/LandingPages/Products/Products";
import Team from "./Container/LandingPages/Team/Team";
import Contact from "./Container/LandingPages/Contact/Contact";
import Disclaimer from "./Container/LandingPages/Footer/Disclaimer";
import TermsAndConditions from "./Container/LandingPages/Footer/TermsAndConditions";
import PrivacyPolicy from "./Container/LandingPages/Footer/PrivacyPolicy";
import TermsofService from "./Container/LandingPages/Footer/TermsofService";
import CancellationPolicy from "./Container/LandingPages/Footer/CancellationPolicy";
import RefundPolicy from "./Container/LandingPages/Footer/RefundPolicy";
import Home from "./Container/LandingPages/Home/Home";
import PaymentStatus from "./Container/PaymentStatus/PaymentStatus";
import PaymentStatus90Pre from "./Container/PaymentStatus90Pre/PaymentStatus90Pre";


const App = () => {
  return (
    <div className="app_style">
      <BrowserRouter>
        <Routes>
          <Route path="/admin-dashboard" element={<WelcomeAdmin />} />
          <Route path="/customer-dashboard" element={<CostomerDashboard />} />
          <Route
            path="/lorry-broker-dashboard"
            element={<LorryBrokerDashboard />}
          />
          <Route
            path="/lorry-wala-dashboard"
            element={<LorryBrokerDashboard />}
          />
          {/* <Route path='/' element={<UserLogin />} /> */}
          <Route path="/signup" element={<Signup />} />
          <Route path="/sent-password-link" element={<UserEmail />} />
          <Route
            path="/user-verification/:userType/:token/:cur_pass/:client_id"
            element={<UserRegLinkVerification />}
          />
          <Route
            path="/password-link-verification/:userType/:client_id/:token"
            element={<ForgotPassLinkVerification />}
          />
          <Route
            path="/reset-password/:client_id/:userType"
            element={<ResetPassword />}
          />
          <Route
            path="/change-password/:client_id/:userType"
            element={<ChangePassword />}
          />
          <Route path="/" element={<Home />} />

          <Route path="/company" element={<Company />} />
          <Route path="/products" element={<Products />} />
          <Route path="team" element={<Team />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsofService />} />
          <Route path="/cancellationPolicy" element={<CancellationPolicy />} />
          <Route path="/refundPolicy" element={<RefundPolicy />} />
          <Route path='/prime/pg/v1/payment-status/:trid/:mt/:clientId/:bId/:rMoney' element={<PaymentStatus />} />
          <Route path='/prime/90pre/pg/v1/payment-status/:trid/:mt/:clientId/:bId/:sendBidsNumber' element={<PaymentStatus90Pre/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
