import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaCircle, FaMapMarkerAlt, FaRupeeSign, FaUserCircle, FaWindowClose } from 'react-icons/fa';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SimpleBackdrop from '../../../BackDrop/BackDrop';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import MyTimer from './Timer';
import TextField from '@mui/material/TextField';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export default function Users() {

    const [open2, setOpen2] = React.useState(false);

    const time = new Date();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [lwcurrentBids, setLwCurrentBids] = React.useState([])
    const [lbCurrentBids, setLbCurrentBids] = React.useState([])
    const [vehicle, setVehicle] = React.useState()
    const [capacity, setCapacity] = React.useState()
    const [material, setMaterial] = React.useState()
    const [endLocation, setEndLocation] = React.useState()
    const [startLocation, setSatrtLocation] = React.useState()
    const [bidId, setBidId] = React.useState()
    const [name, setName] = React.useState()
    const [distance, setDistance] = React.useState()
    const [isPayment, setIsPayment] = useState('')
    const [halfAmm, setHalfAmm] = useState('')
    const [wait1, setWait1] = useState(false)
    const [rMoney, setRMoney] = useState('')
    const [transporterId, setTransporterId] = useState()


    const bidSuccessFullySend = () => {
        Swal.fire({
            title: "Bid Successful",
            text: "Your 10%  payment successful, thank you !...",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
        }).then((result) => {
            if (result.value) {
                setWait1(false)
                setOpen1(false)
                setOpen(false)
                setOpen2(false)
            } else {
                window.location.reload()
            }
        });
    }


    const payHalfAmmount = () => {
        setOpen1(false)
        setOpen2(true)
        setHalfAmm(isPayment)
    }

    

    const curBidsRes = () => {
        const email = sessionStorage.getItem('email')
        
        axios.post('https://pmapi.emedha.in/customer/bids-status/current', {
            email
        }).then((res) => {
            setLwCurrentBids(res.data.res)
        })
    }

    const [viewBidValue, setViewBidValue] = React.useState([])

    const viewAcceptedBids = (bidId) => {
        axios.post('https://pmapi.emedha.in/customer/bids-status/view-accepted-bids', {
            bidId
        }).then((res) => {
            setViewBidValue(res.data.res)
            setOpen(true)
            setWait((false))
        })
    }

    React.useEffect(() => {
        curBidsRes()
    }, [])

    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    const [wait, setWait] = React.useState(false)
    const [dispatch, setDispatch] = React.useState()

    const bidsDetails = (bidId, startLocation, endLocation, material, capacity, vehicle, name, distance, dispatch) => {
        viewAcceptedBids(bidId)
        setWait(true)
        setTimeout(() => {
            setMaterial(material)
            setSatrtLocation(startLocation)
            setDispatch(dispatch)
            setEndLocation(endLocation)
            setBidId(bidId)
            setCapacity(capacity)
            setVehicle(vehicle)
            setName(name)
            setDistance(distance)
        }, 500)
    }

    const [isBidValue, setIsBidValue] = React.useState(false)
    const [isShowName, setIsShowName] = React.useState(false)

    const [check180, setCheck180] = React.useState(false)
    const [open1, setOpen1] = React.useState(false);

    const check180min = (bidId) => {
        axios.post('https://pmapi.emedha.in/customer/bids-status/check180min', {
            bidId
        }).then((res) => {
            if (res.data.status === 409) {
                Swal.fire({
                    title: "Wait..!",
                    text: `Your bid is Live please wait: ${res.data.minutus} min `,
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                })
            } else {
                setCheck180(false)
                setOpen1(true)
            }
        })
    }

    const payment = (hamm, client_id) => {

        setWait1(true)
        axios.post('https://pmapi.emedha.in/payment/successful/10pre', {
            bidId,
            hamm,
            rMoney,
            transporterId,
            client_id
        }).then((res) => {
            if (res.data.status === 200) {
                bidSuccessFullySend()
                setOpen1(false)
                setOpen(false)
                setOpen2(false)
                setWait1(false)
            }
        })
    }

    return (
        <Paper elevation={0}>
            <h4>Live Bids</h4>
            <TableContainer sx={{ height: 61 + "vh" }} >
                <Table>
                    <TableHead >
                        <TableRow>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Bid Id
                            </TableCell>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Dispatch Date
                            </TableCell>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Product
                            </TableCell>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 120, fontWeight: 'bold' }}
                            >
                                Destination
                            </TableCell>

                            <TableCell

                                align={"start"}
                                style={{ top: 57, minWidth: 23, fontWeight: 'bold' }}
                            >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lwcurrentBids
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>

                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.send_bids_id.substring(row.send_bids_id.length + -16, 0) + "........"}</TableCell>
                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.dispatchTime}</TableCell>

                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.material}</TableCell>
                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 120, fontSize: 12 }}>{row.endLocation}</TableCell>

                                        <TableCell align={"start"}
                                            style={{ top: 57, minWidth: 23, flexDirection: 'row', alignItems: 'center' }}>
                                            <span onClick={() => bidsDetails(row.send_bids_id, row.startLocation, row.endLocation, row.material, row.capacity, row.vehicle, row.customerName, row.distance, row.dispatchTime)} style={{ cursor: 'pointer' }}>
                                                <button style={{ backgroundColor: 'rgb(36, 145, 36)', fontSize: '0.9rem', border: 'none', borderRadius: '0.9rem', color: 'white' }} >View</button>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>

                </Table>
                <Box sx={{ mt: 14 }} >
                    {
                        (lwcurrentBids.length === 0) && (
                            <div className='card' style={{ alignItems: 'center', boxShadow: 'none', justifyContent: 'center', border: 'none' }}>
                                <img width='80px' src='https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr' />
                                <h5>No Live Bids</h5>
                            </div>
                        )
                    }
                </Box>
            </TableContainer>
            <div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'

                >
                    <DialogContent style={{ padding: 20 }}>
                        <div className='card' style={{ height: '4.8rem', width: '67rem', flexDirection: 'row', alignItems: 'center', borderRadius: '0.1rem' }} >
                            <FaWindowClose onClick={() => {
                                setOpen(false)
                                setIsBidValue(false)
                            }} style={{ fontSize: 42, color: 'lightgray', marginLeft: 10, cursor: 'pointer' }} />
                            <h4 style={{ marginTop: 9.6, marginLeft: 7 }}>Bid Details</h4>
                        </div>
                        <div style={{ marginTop: 12 }}>

                            {
                                !isBidValue && (
                                    <div>
                                        <div style={{ backgroundColor: '#12124e', marginTop: 20, paddingLeft: 10, borderRadius: '0.2rem' }}>
                                            <h5 style={{ color: 'white' }} >Your Route</h5>
                                            <div style={{ borderBottom: '2px solid white' }} ></div>
                                            <div style={{ position: 'relative', top: 16, backgroundColor: 'white', width: '58rem', borderRadius: '0.4rem', justifyContent: 'space-between', border: 'none', flexDirection: 'row', alignItems: 'center', borderRadius: '0.14rem', padding: 10 }} className='shadow card' >
                                                <Typography style={{ color: 'black', fontSize: '0.8rem', width: '49%' }}><FaMapMarkerAlt style={{ color: 'gold', fontSize: 17, marginTop: '-0.2rem' }} />From: {startLocation}</Typography>
                                                <Typography style={{ color: 'black', fontSize: '0.8rem', width: '49%' }}><FaCircle style={{ color: 'red', fontSize: 14, marginTop: -'0.3rem' }} /> To: {endLocation}</Typography>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 43, backgroundColor: 'rgb(240, 239, 239)', paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }}>
                                            <Typography style={{ fontSize: '0.86rem', fontStyle: 'italic' }}>Vehicle Type:- {vehicle}</Typography>
                                            <Typography style={{ fontSize: '0.86rem', fontStyle: 'italic' }}>Vehicle Capacity:- {capacity}</Typography>
                                            <Typography style={{ fontSize: '0.86rem', fontStyle: 'italic' }}>Product Category / Product Type:- {material}</Typography>
                                            <Typography style={{ fontSize: '0.86rem', fontStyle: 'italic' }}>DIstance:- {distance}</Typography>
                                            <Typography style={{ fontSize: '0.86rem', fontStyle: 'italic' }}>Despatch Time:- {dispatch}</Typography>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        <Box>
                            <Typography style={{ fontSize: 17, fontWeight: 'bold', position: 'relative', top: '1.3rem', color: '#12124e' }}>
                                Top Four Bids Recieved
                                <div style={{ borderBottom: '0.12rem solid #12124e' }} ></div>
                            </Typography>
                            <Box sx={{ flexGrow: 1, mt: 3, height: '15.1rem', borderRadius: '0.4rem', padding: 1 }}>

                                <Grid container spacing={2}>
                                    {
                                        viewBidValue.slice(0, 3).map((data) => {
                                            return (
                                                <Grid item xs={6}>
                                                    <Item sx={{ bgcolor: 'white', borderRadius: '0.0002rem', borderLeft: '2px solid #12124e', position: 'relative' }} elevation={1} >
                                                        <div >
                                                            <MyTimer expiryTimestamp={time.setSeconds(data.currentMinuts * 60)} />
                                                        </div>
                                                        <Typography style={{ fontSize: 11 }}> Bid ID: {bidId}</Typography>
                                                        {
                                                            data.vehicleReqNum === 1 ? (
                                                                <Typography style={{ marginTop: 8 }}>Number of vehicle: {data.vehicleReqNum} Vehicle</Typography>
                                                            ) : (
                                                                <Typography style={{ marginTop: 8 }}>Number of vehicle: {data.vehicleReqNum} Vehicles</Typography>
                                                            )
                                                        }
                                                        <Typography style={{ marginTop: 10, fontWeight: 'bold' }}>Bid Amount: <FaRupeeSign style={{ fontSize: 13.5, marginTop: '-0.17rem' }} />{data.money} /-</Typography>
                                                        <Button onClick={() => {
                                                            setBidId(data.acceptedBids_id)
                                                            setOpen(false)
                                                            const pay = (data.money) * (10 / 100)
                                                            const rMoney = (data.money) * (90 / 100)
                                                            setRMoney(rMoney)
                                                            setIsPayment(pay)
                                                            check180min(data.acceptedBids_id)
                                                            setTransporterId(data.client_id)
                                                        }} style={{ width: '5rem', height: '1.9rem', borderRadius: '1.3rem', border: '1px solid #12124e', backgroundColor: 'white', color: '#12124e', position: 'absolute', right: '5px', bottom: '5px' }} >Pay</Button>
                                                    </Item>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Box>

                    </DialogContent>
                </Dialog>
                <div>
                    <Dialog
                        open={open1}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Pay Now"}</DialogTitle>
                        <DialogContent style={{ width: '23rem' }} >
                            <DialogContentText id="alert-dialog-slide-description">
                                Make Payment to the further
                            </DialogContentText>
                            <br />
                            <Button style={{ color: 'gray', width: '100%' }} variant='outlined'>10% Payment money is {isPayment}</Button>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={payHalfAmmount}>Pay</Button>
                            <Button onClick={() => setOpen1(false)}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog open={open2} >
                        <DialogTitle style={{ fontSize: 20, textAlign: 'center' }} >Payment Getway</DialogTitle>
                        <Typography style={{ marginLeft: 23 }} >Pay 10% amount to the bid confirmation</Typography>
                        <DialogContent style={{ width: '35rem' }} >

                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Bid Confirmation Price"
                                type="email"
                                fullWidth
                                variant="standard"
                                value={halfAmm}
                            />
                        </DialogContent>
                        <br />
                        <DialogActions>
                            {
                                !wait1 && (
                                    <Button style={{ width: '7.8rem', backgroundColor: '#1f1f46', color: 'white' }} onClick={() => {
                                        payment(halfAmm)
                                    }} >Pay</Button>
                                )
                            }
                            {
                                wait1 && (
                                    <Button disabled={true} variant='contained' style={{ width: '7.5' }} >Paying...</Button>
                                )
                            }
                            <Button onClick={() => {
                                setOpen1(false)
                                setOpen(false)
                                setOpen2(false)
                            }} style={{ width: '7.8rem', backgroundColor: 'red', color: 'white' }} >Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            {
                wait && (
                    <SimpleBackdrop />
                )
            }
        </Paper>
    );
}