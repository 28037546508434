 import React from 'react'
import './style.css'
import { FaEyeSlash, FaMobileAlt, FaUserAlt, FaUserLock } from 'react-icons/fa'
import { Typography } from '@mui/material';
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleBackdrop from '../../BackDrop/BackDrop';
import Swal from 'sweetalert2';


export const UserLogin = () => {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function refreshPage() {
        window.location.reload(false);
    }

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isBackDrop, setIsBackDrop] = useState(false)

    const navigate = useNavigate()


    const login = () => {
        
        setIsBackDrop(true)
        const key = "prime_moovers_hshhgdfynkjhttw2378498bhkhdcrgjdbwe't09jefjs"
        axios.post('https://pmapi.emedha.in/user/login', {
            email,
            password
        }).then((res) => {
            if (res.data.type === 'Customer') {
                sessionStorage.setItem('client_id', res.data.client_id)
                navigate('/customer-dashboard')
                sessionStorage.setItem('key', key)
                sessionStorage.setItem('email', email)
                sessionStorage.setItem('type', res.data.type)
                sessionStorage.setItem('location', res.data.location)
                refreshPage()
                setIsBackDrop(false)
            } else {
                if (res.data.type === 'LorryWala') {
                    sessionStorage.setItem('client_id', res.data.client_id)
                    navigate('/lorry-wala-dashboard')
                    sessionStorage.setItem('key', key)
                    sessionStorage.setItem('email', email)
                    sessionStorage.setItem('type', res.data.type)
                    refreshPage()
                    setIsBackDrop(false)
                } else {
                    if (res.data.type === 'LorryBroker') {
                        sessionStorage.setItem('client_id', res.data.client_id)
                        localStorage.setItem('client_id',res.data.client_id)
                        navigate('/lorry-broker-dashboard')
                        sessionStorage.setItem('key', key)
                        sessionStorage.setItem('email', email)
                        sessionStorage.setItem('type', res.data.type)
                        refreshPage()
                        setIsBackDrop(false)
                    } else {
                        if (res.data.type === 'admin') {
                            navigate('/admin-dashboard')
                            sessionStorage.setItem('key', key)
                            sessionStorage.setItem('email', email)
                            refreshPage()
                            setIsBackDrop(false)
                        } else {
                            LoginError()
                            setEmail('')
                            setPassword('')
                            setIsBackDrop(false)
                        }
                    }
                }
            }
        })
    }


    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            login()
        }

        setValidated(true);
    };


    const LoginError = (err) => {
        Swal.fire({
            title: "Error..!",
            text: 'Your email or password is not valid',
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.value) {
                return
            }
        });
    }



    return (
        <div className='container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }} >
            <div className='col-8 mx-auto signup-con' style={{ borderRadius: '0.23rem' }}  >
                <div className='row' style={{ borderRadius: '0.3rem', backgroundColor: 'white' }} >
                    <div className='col col-md-6 col-12' style={{ borderRadius: '0.23rem', backgroundColor: 'rgb(41, 174, 227)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <div style={{ marginTop: '4.3rem', marginBottom: '4.2rem' }} >
                            <div className='d-none d-md-flex' style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
                                <center><img style={{ width: '300px', borderRadius: '50%' }} src="https://img.freepik.com/free-vector/sign-page-abstract-concept-illustration_335657-3875.jpg?w=1380&t=st=1693134615~exp=1693135215~hmac=8aeb0c9d03bdbbb414b4ff7cf4f98fe4a3411663917be0cbb1f1cac9a27d2e68" /></center>
                            </div>
                            <div className='d-md-none d-flex' >
                                <center><img style={{ width: '290px', borderRadius: '50%' }} src='https://img.freepik.com/free-vector/sign-page-abstract-concept-illustration_335657-3875.jpg?w=1380&t=st=1693134615~exp=1693135215~hmac=8aeb0c9d03bdbbb414b4ff7cf4f98fe4a3411663917be0cbb1f1cac9a27d2e68' /></center>
                            </div>
                            <h4 style={{ fontWeight: '700', fontSize: '1.2rem', textAlign: 'center', marginBottom: 13, color: 'white', marginTop: 13 }} >Our trucks save you bucks</h4>
                            <Typography style={{ padding: 17 }} >
                                <Typography style={{ fontSize: '0.9rem', color: 'white', textAlign: 'center', lineHeight: '1.2rem' }} >Find truck loads online PAN-India and maximize the profits of your transport business with return loads for all your trucks!</Typography>
                            </Typography>
                        </div>
                    </div>
                    <div className='col col-md-6 col-12' style={{ borderRadius: '0.23rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <div style={{ width: '310px', height: '462px' }} >
                            <center><img src={require('../../GlobleImageContainer/images/PrimeMoovers.png')} style={{ width: '100px' }} /></center>
                            <h3 style={{ textAlign: 'center', color: '#023A51', fontSize: '28px', marginBottom: '1.3rem', fontWeight: '900', fontSize: '1.4rem', textShadow: '0.3px 0.5px 0.3px rgb(41, 174, 227)' }} >Prime Moovers Login</h3>
                            <div style={{ width: '100%' }} >
                                <div className='inputField' style={{ height: '55px', width: '100%', position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '0.3rem', marginBottom: 26.7, paddingLeft: 23 }} >
                                    <FaUserAlt />
                                    <input onChange={(e) => setEmail(e.target.value)} style={{ paddingLeft: '1.3rem', width: '100%', outline: 'none', borderRadius: '0.1rem', border: 'none', color: '#023A51', fontWeight: '500' }} placeholder='Enter email' />
                                </div>
                                <div className='inputField' style={{ height: '55px', width: '100%', position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '0.3rem', marginBottom: 26.7, paddingLeft: 23 }} >
                                    <FaUserLock />
                                    <input onChange={(e) => setPassword(e.target.value)} style={{ paddingLeft: '1.3rem', width: '100%', outline: 'none', borderRadius: '0.1rem', border: 'none', color: '#023A51', fontWeight: '500' }} type='password' placeholder='Enter your password' />
                                    <FaEyeSlash style={{ position: 'absolute', right: 12 }} />
                                </div>
                                <button onClick={handleSubmit} style={{ width: '100%', height: '55px', borderRadius: '0.13rem', backgroundColor: 'rgb(41, 174, 227)', color: 'white', border: 'none', fontWeight:'bold' }} >Login</button>
                                <br />

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 32 }} >
                                    <p style={{ fontSize: '14px', fontWeight: '600', color: 'rgb(41, 174, 227)' }} >Forgot Password ?</p>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-0.4rem' }} >
                                    <div style={{ width: '45%', borderBottom: '1.8px solid #004952' }} ></div>
                                    <p style={{ position: 'relative', top: 6, color: 'rgb(69, 75, 75)', fontWeight: '400' }} >or</p>
                                    <div style={{ width: '45%', borderBottom: '1.8px solid #004952' }} ></div>
                                </div>
                                <p onClick={()=>navigate('/signup')} style={{ cursor:'pointer',fontSize: '14px', textAlign: 'center', fontWeight: '400', color: '#023A51', marginTop: '1.1rem' }} >Don,t have Account? <span style={{ color: 'rgb(41, 174, 227)', fontSize:'14px' }} >Sign Up</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isBackDrop && (
                    <SimpleBackdrop />
                )
            }
        </div>
    )
}