import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import Swal from "sweetalert2";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";

import TextField from "@mui/material/TextField";

import React, { useEffect } from "react";
import { GoArrowUp } from "react-icons/go";
import "./index.css";
import {
  FaAccusoft,
  FaAdn,
  FaCheckCircle,
  FaUsers,
  FaWindowClose,
} from "react-icons/fa";
import { FaTruckLoading } from "react-icons/fa";

import { styled, useTheme } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "Auto",
  color: theme.palette.text.secondary,
}));

const Transactions = () => {
  const [currentBids, setCurrentBids] = React.useState([]);

  const curBidsRes = () => {

    axios
      .post("https://pmapi.emedha.in/admin/10pre-bids-data", {
      
      })
      .then((res) => {
        setCurrentBids(res.data);
        console.log(res.data)
      });
  };

  const [acceptBids, setAcceptBids] = React.useState([]);

  const accBidsRes = () => {

    axios
      .post("https://pmapi.emedha.in/admin/accepted-bids-data", {

      })
      .then((res) => {
        setAcceptBids(res.data);
        console.log(res.data)
      });
  };

  React.useEffect(() => {
    accBidsRes();
  }, []);

  React.useEffect(() => {
    curBidsRes();
  }, []);

  const [succBids, setSuccBids] = React.useState([]);

  const succRes = () => {

    axios
      .post("https://pmapi.emedha.in/admin/90pre-bids-data", {
  
      })
      .then((res) => {
        setSuccBids(res.data);
      });
  };

  useEffect(() => {
    succRes();
  }, []);



  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [lwcurrentBids, setLwCurrentBids] = React.useState([]);

  const curBidsRess = () => {
 

    // https://pmapi.emedha.in/admin

    axios
      .post("https://pmapi.emedha.in/admin/bids-data", {
     
      })
      .then((res) => {
        setLwCurrentBids(res.data || []);
        console.log(res.data)
      });
  };

  React.useEffect(() => {
    curBidsRess();
  }, []);

  const [isopen1,setOpen1]=useState(true)
  const [isopen2,setOpen2]=useState(false)
  const [isopen3,setOpen3]=useState(false)
  const [isopen4,setOpen4]=useState(false)

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid style={{ height: "10rem", marginBottom: "0.8rem" }} item xs={3}>
            <Item
              elevation={1}
              sx={{
                height: "9.5rem",
                position: "relative",
                backgroundColor: "#f2d350",
              }}
              onClick={() => {
                setOpen1(true);
                setOpen2(false);
                setOpen3(false);
                setOpen4(false);
              }}
              
            >
              <h1
                style={{
                  fontSize: 16,
                  marginTop: 14,
                  fontWeight: "bold",
                  color: "#0d0d4a",
                }}
              >
                Called Bids
              </h1>
              <div
                style={{
                  position: "absolute",
                  right: "0.8rem",
                  top: "0.7rem",
                  padding: "0.4rem",
                  borderRadius: "0.3rem",
                }}
              >
                <FaAccusoft
                  fontSize={28}
                  style={{ position: "relative" }}
                  color="#67aabd"
                />
              </div>
              <p style={{ fontSize: 29, color: "#143463", fontWeight: "bold" }}>
             {lwcurrentBids.length}
              </p>
              <h1
                style={{
                  fontSize: 11,
                  color: "gray",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  marginTop: "1.2rem",
                }}
              >
                All Current Bids
              </h1>
            </Item>
          </Grid>
          <Grid style={{ height: "10rem", marginBottom: "0.8rem" }} item xs={3}>
            <Item
              elevation={1}
              sx={{
                height: "9.5rem",
                position: "relative",
                backgroundColor: "#143463",
              }}
              onClick={() => {setOpen1(false),setOpen2(true),setOpen3(false),setOpen4(false)}}
            >
              <h1
                style={{
                  fontSize: 16,
                  marginTop: 14,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                All Accepted Bids
              </h1>
              <div
                style={{
                  position: "absolute",
                  right: "0.8rem",
                  top: "0.7rem",
                  padding: "0.4rem",
                  borderRadius: "0.3rem",
                }}
              >
                <FaAdn
                  fontSize={28}
                  style={{ position: "relative" }}
                  color="gold"
                />
              </div>
              <p style={{ fontSize: 29, color: "#f2d350", fontWeight: "bold" }}>
        {acceptBids.length}  
              </p>
              <h1
                style={{
                  fontSize: 11,
                  color: "#f7f5ed",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  marginTop: "1.2rem",
                }}
              >
                Your Total Accepted bids
              </h1>
            </Item>
          </Grid>
          <Grid style={{ height: "10rem", marginBottom: "0.8rem" }} item xs={3}>
            <Item
              elevation={1}
              sx={{
                height: "9.5rem",
                position: "relative",
                backgroundColor: "#30aee1",
              }}
              onClick={() => {setOpen1(false),setOpen2(false),setOpen3(true),setOpen4(false)}}
            >
              <h1
                style={{
                  fontSize: 16,
                  marginTop: 14,
                  fontWeight: "bold",
                  color: "#0d0d4a",
                }}
              >
                10% Paid Bids
              </h1>
              <div
                style={{
                  position: "absolute",
                  right: "0.8rem",
                  top: "0.7rem",
                  padding: "0.4rem",
                  borderRadius: "0.3rem",
                }}
              >
                <FaCheckCircle
                  fontSize={28}
                  style={{ position: "relative" }}
                  color="green"
                />
              </div>
              <p style={{ fontSize: 29, color: "white", fontWeight: "bold" }}>
               {currentBids.length}
              </p>
              <h1
                style={{
                  fontSize: 11,
                  color: "gray",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  marginTop: "1.2rem",
                }}
              >
                Total 10% Successful Bids
              </h1>
            </Item>
          </Grid>
          <Grid style={{ height: "10rem", marginBottom: "0.8rem" }} item xs={3}>
            <Item
              elevation={1}
              sx={{
                height: "9.5rem",
                position: "relative",
                backgroundColor: "#737471",
              }}
              onClick={() =>{setOpen1(false),setOpen2(false),setOpen3(false),setOpen4(true)}}
            >
              <h1
                style={{
                  fontSize: 16,
                  marginTop: 14,
                  fontWeight: "bold",
                  color: "#0d0d4a",
                }}
              >
                100% Paid Bids
              </h1>
              <div
                style={{
                  position: "absolute",
                  right: "0.8rem",
                  top: "0.7rem",
                  padding: "0.4rem",
                  borderRadius: "0.3rem",
                }}
              >
                <FaTruckLoading
                  fontSize={28}
                  style={{ position: "relative" }}
                  color="rgb(244, 90, 90)"
                />
              </div>
              <p style={{ fontSize: 29, color: "#f2d350", fontWeight: "bold" }}>
                {succBids.length}
              </p>
              <h1
                style={{
                  fontSize: 11,
                  color: "#f7f5ed",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  marginTop: "1.2rem",
                }}
              >
                Total 100% Successful Bids
              </h1>
            </Item>
          </Grid>
        </Grid>
     

      {isopen1  && <Paper elevation={0} style={{marginTop:'2rem'}}>
        <h4 style={{color:'#f6c90c'}}>Called Bids</h4>
        <TableContainer sx={{ height: 61 + "vh" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  Bid Id
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  From Location
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                 To Location
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  Material
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 23, fontWeight: "bold" }}
                >
                  Vehicle
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lwcurrentBids
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                        {row.send_bids_id}
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                        {row.startLocation}
                      </TableCell>

                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                          {row.endLocation}
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                       {row.material}
                      </TableCell>

                      <TableCell
                        align={"start"}
                        style={{
                          top: 57,
                          minWidth: 23,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                     {row.vehicle}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Box sx={{ mt: 14 }}>
            {lwcurrentBids.length === 0 && (
              <div
                className="card"
                style={{
                  alignItems: "center",
                  boxShadow: "none",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <img
                  width="80px"
                  src="https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr"
                />
                <h5>No Live Bids</h5>
              </div>
            )}
          </Box>
        </TableContainer>
      </Paper>}

      {isopen2  &&  <Paper elevation={0} style={{marginTop:'2rem'}}>
        <h4 style={{color:'#143463'}}>Successfull Bids</h4>
        <TableContainer sx={{ height: 61 + "vh" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  Bid Id
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  From Location
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                 To Location
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  Bid Amount
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 23, fontWeight: "bold" }}
                >
                  Transporter Mail
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acceptBids
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                        {row.acceptedBids_id}
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                        {row.fromRoute}
                      </TableCell>

                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                          {row.toRoute}
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                       {row.oneVehicleBidAmount}
                      </TableCell>

                      <TableCell
                        align={"start"}
                        style={{
                          top: 57,
                          minWidth: 23,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                     {row.transporterEmail}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Box sx={{ mt: 14 }}>
            {lwcurrentBids.length === 0 && (
              <div
                className="card"
                style={{
                  alignItems: "center",
                  boxShadow: "none",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <img
                  width="80px"
                  src="https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr"
                />
                <h5>No Live Bids</h5>
              </div>
            )}
          </Box>
        </TableContainer>
      </Paper>}

      {isopen3  &&<Paper elevation={0} style={{marginTop:'2rem'}}>
        <h4 style={{color:'#30aee1'}}>10% Paid Bids</h4>
        <TableContainer sx={{ height: 61 + "vh" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  Bid Id
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  From Location
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                 To Location
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  Bid Amount
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 23, fontWeight: "bold" }}
                >
              paid Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentBids
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                        {row.acceptedBids_id}
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                        {row.fromRoute}
                      </TableCell>

                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                          {row.toRoute}
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                       {row.oneVehicleBidAmount}
                      </TableCell>

                      <TableCell
                        align={"start"}
                        style={{
                          top: 57,
                          minWidth: 23,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                     {row.oneVehicleBidAmount/100*10}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Box sx={{ mt: 14 }}>
            {lwcurrentBids.length === 0 && (
              <div
                className="card"
                style={{
                  alignItems: "center",
                  boxShadow: "none",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <img
                  width="80px"
                  src="https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr"
                />
                <h5>No Live Bids</h5>
              </div>
            )}
          </Box>
        </TableContainer>
      </Paper>}

      {isopen4  && <Paper elevation={0} style={{marginTop:'2rem'}}>
        <h4 style={{color:'#737471'}}>100% Paid Bids</h4>
        <TableContainer sx={{ height: 61 + "vh" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  Bid Id
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  From Location
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                 To Location
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 120, fontWeight: "bold" }}
                >
                  Bid Amount
                </TableCell>

                <TableCell
                  align={"start"}
                  style={{ top: 57, minWidth: 23, fontWeight: "bold" }}
                >
              paid Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {succBids
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                        {row.acceptedBids_id}
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                        {row.fromRoute}
                      </TableCell>

                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                          {row.toRoute}
                      </TableCell>
                      <TableCell
                        align={"start"}
                        style={{ top: 57, minWidth: 120, fontSize: 12 }}
                      >
                       {row.oneVehicleBidAmount}
                      </TableCell>

                      <TableCell
                        align={"start"}
                        style={{
                          top: 57,
                          minWidth: 23,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                     {row.oneVehicleBidAmount-row.oneVehicleBidAmount/100*10}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <Box sx={{ mt: 14 }}>
            {lwcurrentBids.length === 0 && (
              <div
                className="card"
                style={{
                  alignItems: "center",
                  boxShadow: "none",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <img
                  width="80px"
                  src="https://img.freepik.com/free-vector/auction-house-abstract-concept-vector-illustration-residential-commercial-property-auction-buy-sell-assets-online-exclusive-bid-consecutive-biddings-business-auctions-abstract-metaphor_335657-1956.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=robertav1_2_sidr"
                />
                <h5>No Live Bids</h5>
              </div>
            )}
          </Box>
        </TableContainer>
      </Paper>}

      </Box>
    </>
  );
};

export default Transactions;
